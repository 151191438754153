
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UploadDocument from "@/views/underwriter/policies/UploadDocument.vue";
import UploadCertificate from "@/views/underwriter/policies/UploadCertificate.vue";
import { Field } from "vee-validate";
import variables from "@/router/api";
import store from "@/store";

export default defineComponent({
  name: "underwriter-policy-documentation-process",
  components: {
    Field,
    UploadDocument,
    UploadCertificate,
  },
  data() {
    return {
      displayDocument: false,
      displayCertificate: false,
      clientsRoute: "",
      policyRoute: "",
      policyDetailsRoute: "",
      clientPoliciesRoute: "",
      role: "",
      redirect: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Policy Documentation", ["Policies"]);
    });
  },
  created() {
    // Get the current user role
    const userType = store.getters.currentUser;

    // Policy document upload routes based on user roles
    if (userType.role == variables.BROKER_USER_ROLE) {
      this.clientPoliciesRoute = variables.BROKER_CLIENT_ROUTE;
      this.policyRoute = variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE;
      this.clientsRoute = variables.BROKER_CLIENTS_LIST_ROUTE;
      this.role = variables.BROKER_USER_ROLE;
      this.redirect = "broker-client-policy-listing";
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      this.clientPoliciesRoute = variables.SUPERADMIN_CLIENT_DETAILS_ROUTE;
      this.policyRoute = variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE;
      this.clientsRoute = variables.SUPERADMIN_CLIENTS_LIST_ROUTE;
      this.role = variables.SUPER_ADMIN_USER_ROLE;
      this.redirect = "all-policies-list";
    } else if (userType.role == variables.UNDERWRITER_USER_ROLE) {
      this.clientPoliciesRoute = variables.UNDERWRITERS_CLIENTS_DETAILS_ROUTE;
      this.policyRoute = variables.UNDERWRITERS_POLICY_DETAILS_ROUTE;
      this.clientsRoute = variables.UNDERWRITERS_CLIENTS_ROUTE;
      this.role = variables.UNDERWRITER_USER_ROLE;
      this.redirect = "underwriter-upload-policy-document";
    }

    //Set page title
    document.title = "Policy Documentation | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    uploadDocument() {
      this.displayCertificate = false;
      this.displayDocument = true;
    },
    uploadCertificate() {
      this.displayDocument = false;
      this.displayCertificate = true;
    },
  },
});
